import React from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';

const SelectDateAndTime = ({ content, handleDateChange, disabledDateTime, selectedDates, dContent, selectTime }) => {
    return (
        <div style={{ marginTop: '5rem' }}>
            <div>
                <h5 className='text-title'>Sélectionner un médecin</h5>
                {content}
            </div>

            <div className="row">
                <div className="col-md-5 col-sm-12 mt-3">
                    <h5 className='text-title mb-3'>Veuillez choisir une date</h5>
                    {/* First Month Calendar */}
                    <DatePicker
                        format="YYYY-MM-DD HH:mm:ss"
                        disabledDate={disabledDateTime}
                        onChange={(date) => handleDateChange(date, 'month1')}
                        showTime
                    />
                    {/* Second Month Calendar */}
                    <DatePicker
                        format="YYYY-MM-DD HH:mm:ss"
                        disabledDate={disabledDateTime}
                        onChange={(date) => handleDateChange(date, 'month2')}
                        showTime
                    />
                </div>

                <div className="col-md-7 col-sm-12 mt-3">
                    {selectedDates && selectedDates.length > 0 && (
                        <h5 className='text-title mb-3'>
                            Dates choisies: {selectedDates.map(date => moment(date).format('LL')).join(', ')}
                            {selectTime && ' | Times: ' + selectTime}
                        </h5>
                    )}
                    <div className="date-card rounded">
                        <div className="row text-center mt-3">
                            {selectedDates.length === 0 ? (
                                <h5 className='text-title d-flex justify-content-center align-items-center mt-5'>
                                    Veuillez svp sélectionner une date
                                </h5>
                            ) : (
                                dContent
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SelectDateAndTime;
