import React from 'react';
import './index.css';
import { FaFacebookSquare, FaInstagramSquare, FaLinkedin, FaGithubSquare, FaPhoneAlt, FaEnvelope  } from "react-icons/fa";

const TopHeader = () => {
    return (
        <div id="topbar" className="d-flex align-items-center fixed-top">
            <div className="container d-flex justify-content-between">
                <div className="contact-info d-flex align-items-center">
                    <FaEnvelope className='contact-icon'/> <a href="mailto:support@allomondoc.ci">support@allomondoc.org</a>
                    <FaPhoneAlt className='contact-icon'/> <a href="tel:+2225 07054956">0554757979/0705495688</a> 
                </div>
                <div className="d-none d-lg-flex social-links align-items-center">
                <a href="#" 
                    target="_blank" 
                    rel="noreferrer" 
                    style={{ fontWeight: 'bold', textDecoration: 'none' }}>
                    S'ouscription Médecin
                </a>  
            </div>
         </div>
     </div>  

           
    );
};
export default TopHeader;