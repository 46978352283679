/* global CinetPay */

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const Payment = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    const parsedAmount = query.get('amount');
    setAmount(parsedAmount ? parseFloat(parsedAmount) : 0);

    const script = document.createElement('script');
    script.src = 'https://cdn.cinetpay.com/seamless/main.js';
    script.async = true;
    document.body.appendChild(script);
    
    script.onload = () => {
        CinetPay.setConfig({
          apikey: '60554287756c36b71b7e652.62297849',  // Replace with your actual API key
          site_id: '5878750', // Replace with your actual site ID
          notify_url: 'http://allomondoc.org/notify/',
          mode: 'PRODUCTION'
        });
      };
    
    return () => {
      document.body.removeChild(script);
    };
  }, [query]);

  const checkout = () => {
    if (amount <= 0) {
      alert("Invalid amount. Please try again.");
      return;
    }

    console.log("Amount to charge:", amount);

    CinetPay.getCheckout({
      transaction_id: Math.floor(Math.random() * 100000000).toString(),
      amount: amount,
      currency: 'XOF',
      channels: 'ALL',
      description: 'Service Payment',   
      customer_name: "Joe",
      customer_surname: "Down",
      customer_email: "down@test.com",
      customer_phone_number: "088767611",
      customer_address: "BP 0024",
      customer_city: "Antananarivo",
      customer_country: "CM",
      customer_state: "CM",
      customer_zip_code: "06510",
    });

    CinetPay.waitResponse(function(data) {
      if (data.status === "REFUSED") {
        alert("Votre paiement a échoué");
        window.location.reload();
      } else if (data.status === "ACCEPTED") {
        alert("Votre paiement a été effectué avec succès");
        window.location.reload();
      }
    });

    CinetPay.onError(function(data) {
      console.error("Payment error:", data);
    });
  };

  return (
    <div className="subscription-container">
    <h1 className="subscription-title">SOUSCRIPTION À NOS SERVICES</h1>
    <p className="subscription-amount">Montant: {amount} XOF</p>
    <button className="subscription-button" onClick={checkout}>Payer</button>
  </div>
  
  );
};

export default Payment;
