import { useEffect, useState } from "react";
import Footer from "../Shared/Footer/Footer";
import Header from "../Shared/Header/Header";
import CheckoutPage from "../Booking/BookingCheckout/CheckoutPage";
import PersonalInformation from "../Booking/PersonalInformation";
import { Button, Steps, message, Modal, Card, Row, Col, Input, Select, Tooltip } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import moment from "moment";
import SelectApppointment from "./SelectApppointment";
import useAuthCheck from "../../redux/hooks/useAuthCheck";
import { useCreateAppointmentByUnauthenticateUserMutation } from "../../redux/api/appointmentApi";
import { useDispatch } from "react-redux";
import { addInvoice } from "../../redux/feature/invoiceSlice";
import { useNavigate } from "react-router-dom";

const { Option } = Select;

let initialValue = {
  paymentMethod: 'paypal',
  paymentType: 'creditCard',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  reasonForVisit: '',
  description: '',
  address: '',
  nameOnCard: '',
  cardNumber: '',
  expiredMonth: '',
  cardExpiredYear: '',
  cvv: '',
  speciality: '',
  specialityDetails: '',
};

const specialties = [
  { label: "Cardiologie", value: "Cardiologie", description: "Problèmes cardiaques" },
  { label: "Dermatologie", value: "Dermatologie", description: "Conditions cutanées" },
  { label: "Gynécologie", value: "Gynécologie", description: "Santé des femmes" },
  { label: "Orthopédie", value: "Orthopédie", description: "Problèmes osseux et articulaires" },
  // Ajoutez d'autres spécialités si nécessaire
];

const AppointmentPage = () => {
  const dispatch = useDispatch();
  const { data, role } = useAuthCheck();
  const [current, setCurrent] = useState(0);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectTime, setSelectTime] = useState('');
  const [isCheck, setIsChecked] = useState(false);
  const [selectValue, setSelectValue] = useState(initialValue);
  const [isDisable, setIsDisable] = useState(true);
  const [isConfirmDisable, setIsConfirmDisable] = useState(true);
  const [visible, setVisible] = useState(false);
  const [filteredSpecialties, setFilteredSpecialties] = useState(specialties);
  const [selectedSpecialty, setSelectedSpecialty] = useState('');
  const navigation = useNavigate();
  const [createAppointmentByUnauthenticateUser, { data: appointmentData, isError, isSuccess, isLoading, error }] = useCreateAppointmentByUnauthenticateUserMutation();

  const handleChange = (e) => {
    setSelectValue({ ...selectValue, [e.target.name]: e.target.value });
  };

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  useEffect(() => {
    const { speciality, specialityDetails } = selectValue;
    if (speciality === "Généraliste" || (speciality === "Spécialiste" && specialityDetails)) {
      setIsDisable(false);
    } else {
      setIsDisable(true);
    }
  }, [selectValue]);

  const handleConfirmSchedule = () => {
    const obj = {
      patientInfo: {
        firstName: selectValue.firstName,
        lastName: selectValue.lastName,
        email: selectValue.email,
        phone: selectValue.phone,
        patientId: role !== '' && role === 'patient' ? data.id : undefined,
        scheduleDate: selectedDate,
        scheduleTime: selectTime,
      },
      payment: {
        paymentType: selectValue.paymentType,
        paymentMethod: selectValue.paymentMethod,
        cardNumber: selectValue.cardNumber,
        cardExpiredYear: selectValue.cardExpiredYear,
        cvv: selectValue.cvv,
        expiredMonth: selectValue.expiredMonth,
        nameOnCard: selectValue.nameOnCard
      }
    };
    createAppointmentByUnauthenticateUser(obj);
  };

  useEffect(() => {
    if (isSuccess) {
      message.success("Rendez-vous planifié avec succès");
      setSelectValue(initialValue);
      dispatch(addInvoice({ ...appointmentData }));
      navigation(`/booking/success/${appointmentData?.id}`);
    }
    if (isError) {
      message.error(error?.data?.message);
    }
  }, [isSuccess, isError, isLoading, appointmentData]);

  const handleDateChange = (date) => {
    setSelectedDate(moment(date).format('YYYY-MM-DD HH:mm:ss'));
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleSearch = (value) => {
    const filtered = specialties.filter(specialty =>
      specialty.label.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredSpecialties(filtered);
  };

  const handleSelect = (specialty) => {
    setSelectValue({ ...selectValue, speciality: "Spécialiste", specialityDetails: specialty.value });
    setSelectedSpecialty(specialty.label); // Update the selected specialty
    setVisible(false);
  };

  const steps = [
    {
      title: 'Choisissez une spécialité',
      content: (
        <div>
          <Select
            placeholder="Choisissez une spécialité"
            onChange={(value) => {
              if (value === "Spécialiste") {
                showModal();
              }
              setSelectValue({ ...selectValue, speciality: value });
            }}
            style={{ width: '100%', marginBottom: '20px' }}
          >
            <Option value="">Choisir spécialité</Option>
            <Option value="Généraliste">Généraliste</Option>
            <Option value="Spécialiste">Spécialiste</Option>
          </Select>

          {selectedSpecialty && <p>Spécialité sélectionnée : {selectedSpecialty}</p>} {/* Display selected specialty */}

          <Modal
            title="Choisissez une spécialité"
            visible={visible}
            onCancel={() => setVisible(false)}
            footer={null}
          >
            <Input
              placeholder="Rechercher une spécialité"
              prefix={<SearchOutlined />}
              onChange={(e) => handleSearch(e.target.value)}
              style={{ marginBottom: '20px' }}
            />
            <Row gutter={16}>
              {filteredSpecialties.map((specialty) => (
                <Col span={8} key={specialty.value}>
                  <Tooltip title={specialty.description}>
                    <Card
                      hoverable
                      onClick={() => handleSelect(specialty)}
                      style={{
                        border: selectValue.specialityDetails === specialty.value ? '2px solid #4caf50' : '1px solid #d9d9d9',
                        transition: '0.3s',
                        fontSize: '10px', // Adjusted font size
                      }}
                      bodyStyle={{ textAlign: 'center', padding: '20px' }}
                    >
                      <h3>{specialty.label}</h3>
                      {/* Removed English description */}
                    </Card>
                  </Tooltip>
                </Col>
              ))}
            </Row>
          </Modal>
        </div>
      )
    },
    {
      title: 'Veuillez choisir le jour et la date',
      content: <SelectApppointment
        handleDateChange={handleDateChange}
        selectedDate={selectedDate}
        selectTime={selectTime}
        setSelectTime={setSelectTime}
      />
    },
    {
      title: 'Info du patient',
      content: <PersonalInformation handleChange={handleChange} selectValue={selectValue} />
    },
    {
      title: 'Paiement',
      content: <CheckoutPage
        handleChange={handleChange}
        selectValue={selectValue}
        isCheck={isCheck}
        setIsChecked={setIsChecked}
        data={false}
        selectedDate={selectedDate}
        selectTime={selectTime}
      />,
    },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  return (
    <>
      <Header />
      <div className="container" style={{ marginTop: '8rem', bottom: '5rem' }}>
        <div className="container" style={{ marginBottom: '12rem', marginTop: '8rem' }}>
          <Steps current={current} items={items} />
          <div className='mb-5 mt-3 mx-3'>{steps[current].content}</div>
          <div className='text-end mx-3'>
            {current < steps.length - 1 && (
              <Button type="primary" onClick={next} disabled={isDisable}>
                Suivant
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button type="primary" onClick={handleConfirmSchedule} disabled={isConfirmDisable}>
                Confirmer
              </Button>
            )}
            {current > 0 && (
              <Button style={{ margin: '0 8px' }} onClick={prev}>
                Précédent
              </Button>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AppointmentPage;
