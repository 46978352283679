import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SubHeader from '../Shared/SubHeader';
import Footer from '../Shared/Footer/Footer';
import Header from '../Shared/Header/Header';

const Service = () => {
  const navigate = useNavigate();
  
  const services = [
    {
      title: 'Consultation à Domicile',
      services: [
        {
          title: 'Consultation Dr. Généraliste',
          price: '25,000 FCFA',
          details: ['Prix: 25,000 FCFA', 'Durée: 30 minutes'],
        },
        {
          title: 'Consultation Dr. Spécialiste',
          price: '35,000 FCFA',
          details: ['Prix: 35,000 FCFA', 'Durée: 30 minutes'],
        },
      ],
    },
    {
      title: 'Médecin de Famille',
      services: [
        {
          title: 'Accès',
          price: '15,000 FCFA/mois',
          details: [
            'Couple',
            '0 enfant',
            '2 Rendez-vous de Consultation/mois (téléconsultation ou en cabinet)',
            'Déplacement du Dr à domicile: 5,000 à 15,000 FCFA selon le lieu d\'habitation',
            'Durée: 30 minutes'
          ],
        },
        {
          title: 'Family Basic',
          price: '20,000 FCFA/mois',
          details: [
            'Couple',
            'Au plus 3 enfants',
            '3 Rendez-vous de Consultation/mois (téléconsultation ou en cabinet)',
            'Déplacement du Dr à domicile: 5,000 à 15,000 FCFA selon le lieu d\'habitation'
          ],
        },
        {
          title: 'Family More',
          price: '30,000 FCFA/mois',
          details: [
            'Couple',
            '4 Enfants',
            '4 Rendez-vous de Consultation/mois (téléconsultation ou en cabinet)',
            '5,000F pour chaque enfant supplémentaire',
            'Déplacement du Dr à domicile: 5,000 à 15,000 FCFA selon le lieu d\'habitation'
          ],
        },
      ],
    },
    {
      title: 'Téléconsultation',
      services: [
        {
          title: 'Accès',
          price: '2,000 FCFA / appel',
          details: [
            'Appel pour des conseils médicaux d\'une durée de 10 minutes',
            'Durée: 10 minutes',
          ],
        },
        {
          title: 'Easy',
          price: '10,000 FCFA / appel',
          details: [
            'Appel d\'un médecin généraliste (durée de 20 min)',
            'Prescription d\'une ordonnance avec cachet numérique du Dr',
            'Appel de suivi après 72h',
            'NB: Rendez-vous de contrôle sont gratuits pendant les 15 jours suivant la Consultation sur rendez-vous'
          ],
        },
        {
          title: 'Strong',
          price: '15,000 FCFA / appel',
          details: [
            'Appel d\'un spécialiste pour une durée de 20 min',
            'Prescription d\'ordonnance',
            'Appel de suivi après 72h',
          ],
        },
      ],
    },
    {
      title: 'Soin de Santé à Domicile',
      services: [
        {
          title: 'Soins Infirmiers 1',
          price: '10,000 FCFA',
          details: [
            'Injection',
            'Perfusion',
            'Prise de sang et analyse médicale',
            'Suivi insuffisance cardiaque',
          ],
        },
        {
          title: 'Soins Infirmiers 2',
          price: '15,000 FCFA',
          details: [
            'Pansement simple',
            'Pansement complexe',
            'Pansement post-chirurgical',
            'Suivi diabétique',
            'Tension',
          ],
        },
        {
          title: 'Rééducation (1h)',
          price: '15,000 FCFA',
          details: [
            'Rééducation fonctionnelle par un kiné',
          ],
        },
      ],
    },
  ];

  const handleCheckout = (service) => {
    const priceString = service.price.split(' ')[0].replace(/,/g, ''); // Get amount without formatting
    const amount = parseFloat(priceString); // Convert to float
    navigate(`/payment?amount=${amount}`); // Redirect to Payment component
  };

  // State to keep track of the selected service for details
  const [selectedServiceIndex, setSelectedServiceIndex] = useState(null);

  const toggleDetails = (index) => {
    setSelectedServiceIndex(selectedServiceIndex === index ? null : index);
  };

  return (
    <>
      <Header />
      <SubHeader title="Service" subtitle="Découvrez nos prestations médicales." />

      {services.map((section, sectionIndex) => (
        <div key={sectionIndex} className="container mt-5">
          <h2 className="section-title text-center">{section.title}</h2>
          <div className="row">
            {section.services.map((subService, subIndex) => (
              <div key={subIndex} className="col-lg-4 col-md-6">
                <div className="card mb-4 shadow border-0">
                  <div className="card-body d-flex flex-column">
                    <h5 className="card-title">{subService.title}</h5>
                    <p className="card-text">{subService.price}</p>
                    <button
                      className="btn btn-primary mt-auto"
                      onClick={() => handleCheckout(subService)}
                    >
                      Choisir
                    </button>
                    <button
                      className="btn btn-secondary mt-2"
                      onClick={() => toggleDetails(subIndex)}
                    >
                      {selectedServiceIndex === subIndex ? 'Cacher' : 'Voir detail'}
                    </button>
                    {selectedServiceIndex === subIndex && (
                      <ul className="list-unstyled mt-2">
                        {subService.details.map((detail, index) => (
                          <li key={index} className="text-muted">{detail}</li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
      <Footer />
    </>
  );
};

export default Service;
