import { Button } from 'antd';
import moment from 'moment';
import './index.css';
import { doctorTimeSlot } from '../../constant/global';
import { FaBriefcase, FaRegClock, FaLocationArrow, FaLink, FaCalendarAlt } from "react-icons/fa";
import { useState } from 'react';

const SelectApppointment = ({ selectedDate, handleDateChange, selectTime, setSelectTime }) => {
    const handleSelectTime = (date) => { setSelectTime(date); };

    const amTimeSlot = doctorTimeSlot.filter((item) => item.includes('AM'));
    const pmTimeSlot = doctorTimeSlot.filter((item) => item.includes('PM'));

    const [currentMonth, setCurrentMonth] = useState(moment());

    const generateCalendarDays = (month) => {
        const startOfMonth = month.clone().startOf('month');
        const endOfMonth = month.clone().endOf('month');
        const days = [];

        // Fill days for the current month
        for (let day = 1; day <= endOfMonth.date(); day++) {
            days.push(moment(month).date(day));
        }

        return days; // Return only the valid days of the month
    };

    const daysInMonth = generateCalendarDays(currentMonth);

    return (
        <div style={{ marginTop: '5rem' }}>
            <div className="p-3" style={{ background: '#f8f9fa' }}>
                <div className="row">
                    <div className="col-md-3 col-sm-12 mt-3 info-part border-end">
                        <p className='py-2 border-bottom info-head-date'>Souhaitez-vous planifier un rendez-vous ? Choisissez une date et une heure.</p>
                        <div className='icon-box'>
                            <div className='d-flex gap-3'>
                                <FaBriefcase className='icon' />
                                <p>avec un médecin</p>
                            </div>
                            <div className='d-flex gap-3'>
                                <FaRegClock className='icon' />
                                <p>30 Min</p>
                            </div>
                            <div className='d-flex gap-3'>
                                <FaLocationArrow className='icon' />
                                <p>Angré<br /><span className="form-text">9è tranche immeuble CGK</span></p>
                            </div>
                            <div className='d-flex gap-3'>
                                <FaLink className='icon' />
                                <p>Zoom Meeting</p>
                            </div>
                            <div className='d-flex gap-3'>
                                <FaCalendarAlt className='icon' />
                                <p>{(selectedDate && selectTime) && moment(selectedDate).format('LL') + ' ' + selectTime}</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-5 col-sm-12 mt-3 border-end">
                        <p className='py-2 border-bottom info-head-date'>
                            {selectedDate ? `Selected - ${moment(selectedDate).format('LL')}` : 'Pick a Date'}
                        </p>
                        <div className='info-date-card row'>
                            {daysInMonth.map((date, index) => (
                                <div key={index} className="mb-3 col-md-6" onClick={() => date && handleDateChange(date)}>
                                    <div className={`p-3 mb-3 rounded text-center select-date ${moment(date).format('LL') === moment(selectedDate).format('LL') ? 'active' : ''}`}>
                                        <div className='select-month'>{moment(date).format('MMMM YYYY')}</div>
                                        <div className='select-day-num'>{moment(date).format('D')}</div>
                                        <div className='select-month'>{moment(date).format('dddd')}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="text-end mt-3">
                            <Button onClick={() => setCurrentMonth(currentMonth.clone().subtract(1, 'months'))} disabled={currentMonth.isSame(moment(), 'month')}>
                                Précédent
                            </Button>
                            <Button onClick={() => setCurrentMonth(currentMonth.clone().add(1, 'months'))}>
                                Suivant
                            </Button>
                        </div>
                    </div>

                    <div className="col-md-4 col-sm-12 mt-3">
                        <p className='py-2 border-bottom info-head-date'>
                            {selectTime ? `Selected -  ${selectTime} To ${moment(selectTime, 'hh:mm A').add(30, 'minutes').format('hh:mm A')}` : 'Pick a Time'}
                        </p>

                        <div className='select-time-div'>
                            <h4>Journée <span className='text-secondary'>(8h - 12h)</span></h4>
                            <div className="row text-center mt-3">
                                {amTimeSlot.map((item, id) => (
                                    <div className="col-md-4 col-sm-6" key={id + 155}>
                                        <Button type={item === selectTime ? "primary" : "default"} size='small' className='mb-3' onClick={() => handleSelectTime(item)}>{item}</Button>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className='select-time-div'>
                            <h4>Après-midi <span className='text-secondary'>(13h - 17h)</span></h4>
                            <div className="row text-center mt-3">
                                {pmTimeSlot.map((item, id) => (
                                    <div className="col-md-4" key={id + 155}>
                                        <Button type={item === selectTime ? "primary" : "default"} size='small' className='mb-3' onClick={() => handleSelectTime(item)}> {item} </Button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SelectApppointment;
