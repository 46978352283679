import { Checkbox, message, Button } from 'antd';
import { useEffect, useState } from 'react';
import useAuthCheck from '../../redux/hooks/useAuthCheck';
import { FFmpeg } from '@ffmpeg/ffmpeg';

const ffmpeg = new FFmpeg({ log: true });

const PersonalInformation = ({ handleChange, selectValue, setPatientId = () => {} }) => {
    const { firstName, lastName, email, phone, reasonForVisit, description, address } = selectValue;
    const [checked, setChecked] = useState(false);
    const { data } = useAuthCheck();
    const [isRecording, setIsRecording] = useState(false);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [audioBlob, setAudioBlob] = useState(null);

    const onChange = (e) => {
        setChecked(e.target.checked);
    };

    useEffect(() => {
        if (checked) {
            if (data.id) {
                setPatientId(data.id);
                message.success("User Has Found!");
            } else {
                message.error("User is not Found, Please Login!");
            }
        }
    }, [checked, data, setPatientId]);

    const startRecording = () => {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({ audio: true })
                .then(stream => {
                    const recorder = new MediaRecorder(stream);
                    setMediaRecorder(recorder);

                    const audioChunks = [];
                    recorder.ondataavailable = event => {
                        audioChunks.push(event.data);
                    };

                    recorder.onstop = () => {
                        const audioBlob = new Blob(audioChunks);
                        setAudioBlob(audioBlob);
                        handleChange({ target: { name: 'description', value: 'Audio recorded!' } });
                    };

                    recorder.start();
                    setIsRecording(true);
                })
                .catch(error => {
                    message.error("Could not start recording: " + error.message);
                });
        }
    };

    const stopRecording = () => {
        if (mediaRecorder) {
            mediaRecorder.stop();
            setIsRecording(false);
        }
    };

    const exportAudio = async () => {
        if (!audioBlob) {
            message.error("No audio recorded to export!");
            return;
        }

        // Load FFmpeg
        if (!ffmpeg.isLoaded()) {
            await ffmpeg.load();
        }

        // Create a file name for the audio Blob
        const fileName = 'audio.ogg';
        const audioFile = new File([audioBlob], fileName, { type: 'audio/ogg' });

        // Write the audio file to FFmpeg's file system
        ffmpeg.FS('writeFile', fileName, await audioFile.arrayBuffer());

        // Convert to MP3
        await ffmpeg.run('-i', fileName, 'output.mp3');

        // Read the result
        const data = ffmpeg.FS('readFile', 'output.mp3');

        // Create a URL for the MP3 file
        const mp3Url = URL.createObjectURL(new Blob([data.buffer], { type: 'audio/mp3' }));

        // Create an anchor to download the MP3
        const link = document.createElement('a');
        link.href = mp3Url;
        link.download = 'recording.mp3';
        link.click();

        message.success("Audio exported as MP3!");
    };

    // Fonction pour vérifier si tous les champs sont remplis
    const allFieldsFilled = () => {
        return firstName && lastName && email && phone && reasonForVisit && description && address;
    };

    return (
        <form className="rounded p-3 mt-5" style={{ background: "#f8f9fa" }}>
            <div className="row">
                <Checkbox checked={checked} onChange={onChange}>
                    Vous avez dejà un compte ?
                </Checkbox>

                <div className="col-md-6 col-sm-12">
                    <div className="form-group card-label mb-3">
                        <label>Nom</label>
                        <input onChange={(e) => handleChange(e)} name='firstName' value={firstName} className="form-control" type="text" />
                    </div>
                </div>
                <div className="col-md-6 col-sm-12">
                    <div className="form-group card-label mb-3">
                        <label>Prénoms</label>
                        <input onChange={(e) => handleChange(e)} name='lastName' value={lastName} className="form-control" type="text" />
                    </div>
                </div>
                <div className="col-md-6 col-sm-12">
                    <div className="form-group card-label mb-3">
                        <label>Email</label>
                        <input onChange={(e) => handleChange(e)} name='email' value={email} className="form-control" type="email" />
                    </div>
                </div>
                <div className="col-md-6 col-sm-12">
                    <div className="form-group card-label mb-3">
                        <label>Contact</label>
                        <input onChange={(e) => handleChange(e)} name='phone' value={phone} className="form-control" type="text" />
                    </div>
                </div>
                <div className="col-md-6 col-sm-12">
                    <div className="form-group card-label mb-3">
                        <label>Motif de consultation</label>
                        <textarea rows={8} onChange={(e) => handleChange(e)} name='reasonForVisit' value={reasonForVisit} className="form-control" />
                    </div>
                </div>
                <div className="col-md-6 col-sm-12">
                    <div className="form-group card-label mb-3">
                        <label>Description</label>
                        <textarea rows={8} onChange={(e) => handleChange(e)} name='description' value={description} className="form-control" />
                        <div className="mt-2">
                            <Button onClick={isRecording ? stopRecording : startRecording}>
                                {isRecording ? "Arrêter l'enregistrement" : "Enregistrer par voix"}
                            </Button>
                            <Button onClick={exportAudio} disabled={!audioBlob} className="ml-2">
                                Exporter en MP3
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-sm-12">
                    <div className="form-group card-label mb-3">
                        <label>Adresse</label>
                        <input onChange={(e) => handleChange(e)} name='address' value={address} className="form-control" type="text" />
                    </div>
                </div>
            </div>

           
        </form>
    );
};

export default PersonalInformation;
